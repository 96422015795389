<template>
  <div class="w-fit relative">
    <div
      :class="styling ? styling : ''"
      >
      <!-- @pointerenter="showToolTip = true"
      @pointerleave="showToolTip = false" -->
      <!-- <span class="a8tooltip" :class="'tooltip-'+type" style="width: 10px; height: 10px;" width="10" uk-icon="icon:info;ratio:1.2;"></span> -->
      <!-- <span class="mdi actions-item-icon mdi-chat-alert-outline a8tooltip" :class="'tooltip-'+type" style="width: 10px; height: 10px;" width="10" ></span> -->
      <!-- <svg
      class="a8tooltip"
      :class="'tooltip-' + type"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 7.5L7.52766 7.48617C7.90974 7.29513 8.33994 7.64023 8.23634 8.05465L7.76366 9.94535C7.66006 10.3598 8.09026 10.7049 8.47234 10.5138L8.5 10.5M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM8 5.5H8.005V5.505H8V5.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg> -->
      <h1
        class="a8tooltip"
        :class="'tooltip-' + type"
        >
        {{ title }}
      </h1>
      <!-- @click="handleIntercom(type)" -->
      <!-- <md-icon size="10" style="font-size: 15px!important; color: black">live_help</md-icon> -->
      <!-- <div class="a8tooltip-box" uk-dropdown="mode: click; pos: bottom-left">
              <div class="a8tooltip-box-title">{{tooltip.title}}</div>{{tooltip.content}}</div> -->
    </div>
    <div
      class="absolute z-50"
      :class="[
        position == 'bottom'
          ? '-bottom-9 w-full'
          : position == 'right'
          ? 'left-[108%] -top-0.5 w-fit'
          : '-top-8 w-full',
        moreClass ? moreClass : '',
      ]"
      v-if="showToolTip"
    >
      <div
        class="bg-neutral-700 dark:bg-neutral-800 px-3 py-2 rounded shadow-2xl w-fit mx-auto scale-up-bottom"
      >
        <p
          class="font-semibold text-xs leading-none text-neutral-50 whitespace-nowrap"
        >
          Click to get more info
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  type: { default: "", type: String },
  styling: String,
  title: String,
  bottom: Boolean,
  position: String,
  moreClass: String,
});
const tooltips = ref(null);
const showToolTip = ref(false);
const tooltip = () => {
  let tooltip = tooltips.value;
  if (!tooltip) return { content: "Loading..." };
  if (tooltip[props.type] == undefined) return { content: "Not Available" };
  return tooltip[props.type];
};

const handleIntercom = () => {};

// onBeforeMount(() => {
//   getGlobalSetting({ type: "tooltip" }).then((sRet) => {
//     tooltips.value = sRet;
//   });
// });
</script>
